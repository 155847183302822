import React from "react";
import LayoutNew from "../../components/LayoutNew";
import { Helmet } from "react-helmet";

export default function ContestRules() {
    return (
        <LayoutNew>
            <Helmet>
                <title>General Contest Rules & Regulations</title>
            </Helmet>
            <section className="px-4 py-8 mx-auto font-light prose prose-lg">
                <h1 className="font-bold">Official Contest Rules and Regulations (the "Contest Rules")</h1>
                <p>No purchase necessary to enter or win.</p>
                <p>
                    The Contest is sponsored by Purple Cow Internet (the “Sponsor” or “Purple Cow Internet”).
                </p>
                <h2 className="font-bold">Eligibility:</h2>
                <p>Subject to the eligibility requirements set out herein. In order to enter the Contest, you must:</p>
                <ul>
                    <li>Be a legal resident of Nova Scotia, Canada;</li>
                    <li>Have reached the legal age of majority in Nova Scotia at the time of entry;</li>
                </ul>
                <p>All entries to the Contest are subject to verification. Persons entering the Contest (“Entrants” and each an “Entrant”) may be required to provide evidence of their identity and eligibility associated with their entry to the Contest. Entries will be declared invalid if they are illegible, mechanically reproduced, mutilated, forged, falsified, altered, or tampered with in any way. The Sponsor reserves the right to determine if an entry is invalid at any stage at its sole discretion.</p>
                <p>Employees, representatives and agents of Purple Cow Internet, are not eligible to enter the Contest, participate in the Contest, or to win a Prize, nor are their immediate family members (parent, child, sibling and spouse of each) or any persons who reside or are domiciled in the same household as an employee, representative or agent of the Contest Parties. This Contest is subject to all applicable federal, provincial, municipal, and local laws and regulations. The Contest Rules are subject to change without notice in order to comply with any applicable federal, provincial and municipal laws or the policy of any other entity having jurisdiction over Sponsor. The Contest is intended to be conducted in Nova Scotia, Canada only. The Contest is null and void where prohibited by law.</p>
                <p>All personal information and any other information requested by and/or supplied to the Sponsor for the purpose of this Contest must be truthful, complete, accurate and in no way misleading. The Sponsor reserves the right to disqualify any Entrant at its sole discretion, should any Entrant at any stage supply untruthful, incomplete, inaccurate or misleading personal information and/or any other information.</p>
                <p>The odds of winning the Prize will depend on the total number of eligible entries received in accordance with the Contest Rules during the Contest Period.</p>
                <p>Purple Cow Internet or its designated representative will make three (3) attempts to contact the selected Winners within five (5) business days of the awarding of the prize. If the selected Winners cannot be contacted within three (3) attempts or ten (10) business days of the awarding of the prize (whichever occurs first), or there is a return of any notification as undeliverable; then the selected Winners will be disqualified and Purple Cow Internet reserves the right, in its sole discretion, to randomly select an alternative eligible Entrant from among the remaining eligible entries for the Contest Period.</p>
                <p>In the event that one Winner is contacted and accepts the Prize but Purple Cow Internet is unable to contact the second Winner within three (3) attempts or ten (10) business days of the applicable Draw Date (whichever occurs first), or there is a return of any notification as undeliverable; then the second Winner will be disqualified and Purple Cow Internet reserves the right, in its sole discretion, to terminate the Prize awarded to the second Winner as defined below (Prize conditions and delivery).</p>
                <p>The Winners will be contacted by Purple Cow Internet or its designated representative within five (5) business days of the Draw Date to verify the Winners accept the Prize and to verify the Winners email address for delivery of the Prize.</p>
                <p>The Prize will be emailed to each Winners individual mailing address within 10 to 15 business days from the date that the Winners confirm acceptance of the Prize. No responsibility is assumed by Purple Cow Internet for any deliveries that are returned as undeliverable, or for any Prize after they have been sent to the customer.</p>
                <h2>Intellectual Property:</h2>
                <p>All intellectual property associated with Purple Cow Internet and/or the Contest, including but not limited to trade-marks, trade names, logos, designs, promotional materials, web pages, source code, drawings, illustrations, logos, slogans and representations, are owned by  Purple Cow Internet. All rights are reserved. Unauthorized copying or use of any copyrighted material or intellectual property without the express written consent of its owner is strictly prohibited.</p>
                <h2>General Conditions:</h2>
                <p>By participating, Entrants agree to be bound by these Contest Rules and agree that:</p>
                <ul>
                    <li>They have read and understand the Contest Rules;</li>
                    <li>Any decision of the Sponsor or its agents regarding the awarding of the Prize is binding on them;</li>
                    <li>Where applicable, they will accept a Prize in the form in which it is awarded to them;</li>
                    <li>Acceptance of a Prize is subject to the terms outlined in these Contest Rules;</li>
                    <li>All Contest entries become the property of Purple Cow Internet, which assumes no responsibility for lost, stolen, delayed, incomplete or misdirected entries.</li>
                    <li>The Contest is subject to all applicable federal, provincial and municipal laws.</li>
                    <li>The decisions of the Contest judges with respect to all aspects of the Contest are final and binding on all Entrants, without right of appeal, including, without limitation, any decisions regarding the eligibility/disqualification of entries.</li>
                </ul>
                <h2 className="font-bold">Restrictions:</h2>
                <p>The following are prohibited and will result in automatic disqualification from the Contest:</p>
                <ul>
                    <li>Using any method that artificially increases odds of winning;</li>
                    <li>Non-compliance with the Contest Rules; and</li>
                    <li>Any other act which the Sponsor determines in its sole discretion, jeopardizes the integrity or proper conduct of the Contest.</li>
                </ul>
                <h2 className="font-bold">Privacy:</h2>
                <p>By entering the Contest, an Entrant agrees:</p>
                <ul>
                    <li>To the collection, use, and disclosure by the Sponsor of the Entrant’s personal information for the purposes of administering the Contest and, if applicable, awarding one of the Prizes, including but not limited to, releasing the name of the Prize Winners to those who request such information;</li>
                    <li>That personal information collected by the Sponsor in connection with the Contest may be disclosed to the Contest Parties for storing, sharing and use of personally identifiable information submitted with his/her entry only to the extent necessary to fulfill the Prize or administer and/or promote the Contest.</li>
                </ul>
            </section>
        </LayoutNew>
    );
}
